import Routerx from '@/routerx'
import { http } from '@/utils'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Breadcrumb, Button, Card, Flex, Form, Input, Space, Spin, Typography, message } from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

const { TextArea } = Input
const { Title } = Typography

interface ImportDataType {
  batch_id: number
  content: string
}

const BatchStockImport = () => {
  const params = useParams()
  const batchId = Number(params.id)
  const [content, setContent] = useState('')
  const [loading, setLoading] = useState(false)

  const onFinish = async (values: ImportDataType) => {
    setLoading(true)
    const { batch_id, content } = values
    const params = {
      batch_id,
      content,
    }
    try {
      await http.post(`/v1/batchstock/import`, { ...params })
      Routerx.navigate('/batch')
      message.success(`导入成功`)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error(error.response?.data?.message || '失败')
      }
    }
    setLoading(false)
  }

  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue({ batch_id: batchId })
  }, [batchId, form])

  return (
    <div className="publish">
      <Flex
        justify="space-between"
        gap="small"
        vertical
      >
        <Breadcrumb
          style={{ margin: '16px 0 5px 0' }}
          separator=">"
          items={[
            {
              title: '股池',
            },
            {
              title: <Link to="/batch">批次列表</Link>,
            },
            {
              title: '导入',
            },
          ]}
        />
        <Title
          level={4}
          style={{ margin: '5px 0 10px 0' }}
        >
          <Flex
            justify="flex-start"
            align="center"
            gap="small"
            vertical={false}
          >
            导入
            <Button
              type="dashed"
              shape="circle"
              size="small"
              icon={<ArrowLeftOutlined />}
              style={{ marginLeft: '16px' }}
              onClick={() => Routerx.navigate(`/batch`)}
            ></Button>
          </Flex>
        </Title>

        <Spin spinning={loading}>
          <Card>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ type: 1 }}
              onFinish={onFinish}
              form={form}
            >
              <Form.Item
                label="批次ID"
                name="batch_id"
              >
                <Input
                  value={batchId}
                  style={{ width: 400 }}
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                label="导入内容"
                name="content"
              >
                <TextArea
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  placeholder="请输入导入内容"
                  autoSize={{ minRows: 3 }}
                />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 4 }}>
                <Space>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                  >
                    导入
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Card>
        </Spin>
      </Flex>
    </div>
  )
}

export default BatchStockImport
